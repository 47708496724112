import React from "react"
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import './imageGallery.css'

export const imageTypeNarrow = graphql`
fragment imageTypeNarrow on File {
  childImageSharp {
    fluid(maxHeight: 2000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`

const HEADER_SIZE = '150px';

const IMAGES = [{
  ratio: 0.56,
  name: 'image1',
  mobile: true
}, {
  ratio: 0.56,
  name: 'image2',
  mobile: true
}, {
  ratio: 0.56,
  name: 'image3',
  mobile: true
}, {
  ratio: 0.56,
  name: 'image4',
  mobile: true
}, {
  ratio: 0.56,
  name: 'image5',
  mobile: true
}, {
  ratio: 0.56,
  name: 'image6',
  mobile: true
}, {
  ratio: 0.56,
  name: 'image7',
  mobile: true
}, {
  ratio: 0.84,
  name: 'image8',
  mobile: false
}, {
  ratio: 0.56,
  name: 'image9',
  mobile: true
}, {
  ratio: 1.56,
  name: 'image10',
  mobile: false
}, {
  ratio: 1.5,
  name: 'image11',
  mobile: false
}, {
  ratio: 1.38,
  name: 'image12',
  mobile: false
}, {
  ratio: 1.5,
  name: 'image13',
  mobile: false
}, {
  ratio: 1.5,
  name: 'image14',
  mobile: false
}]

class ImageGallery extends React.Component {

  constructor(props) {
	  super(props);
	  this.state = {
      currentImageIndex: 0,
      prevImageIndex: 0,
      activeFirstImage: false,
      innerHeight: 0, 
      innerWidth: 0
    };
	}

  componentDidMount() {
    this.setState({
      ...this.state,
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth
    });

	  this.galleryInterval = setInterval(
      () => this.newImage(1),
      8000
	  );
	}
  
	componentWillUnmount() {
	  clearInterval(this.galleryInterval);
  }

  
  nextPreviousIndex(nextOrPrevious) {
    const mod = (x, n) => (x % n + n) % n;
    const futureImageIndex = mod(this.state.currentImageIndex + nextOrPrevious,IMAGES.length);

    if (
      (this.props.mobile && IMAGES[futureImageIndex].mobile) ||  // on mobile: display only portrait images
      (!this.props.mobile && (((window.innerWidth / window.innerHeight) - IMAGES[futureImageIndex].ratio)) * window.innerWidth > 300)  //on desktop: display only images that has space for logo
    ) {
      return futureImageIndex;
    } else {
      return this.nextPreviousIndex(nextOrPrevious > 0 ? nextOrPrevious + 1 : nextOrPrevious - 1);
    }

  }

  newImage(nextOrPrevious) {
    clearInterval(this.galleryInterval);
    this.galleryInterval = setInterval(() => this.newImage(1), 8000);

    const nextImageIndex = this.nextPreviousIndex(nextOrPrevious);

	  this.setState({
      ...this.state,
      prevImageIndex: this.state.currentImageIndex,
      currentImageIndex: nextImageIndex,
      activeFirstImage: !this.state.activeFirstImage
    });

    this.props.onImageChange(IMAGES[nextImageIndex].ratio)
  
	}

  render() {
    const {data, mobile, info} = this.props;
    const {currentImageIndex, activeFirstImage, prevImageIndex, innerHeight, innerWidth} = this.state;

    console.log('TEST', innerHeight, innerWidth);
    return (
      <div style={{
        height: mobile ? 'calc(100vh - ' + HEADER_SIZE +')' : '100vh', 
        width: 'auto',
        position: 'relative', 
        float: mobile ? null : 'right',
        marginTop: mobile ? HEADER_SIZE : 0
      }}>
        {!mobile && <div className="animatedWidth" style={{width: info ? 0 : innerHeight * IMAGES[this.state.currentImageIndex].ratio, margin: 0}}></div>}
        <div className="animatedOpacity" style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, opacity: activeFirstImage ? 1 : 0}}>
          {!mobile && <Img style={{height: '100vh', width: 'auto'}} imgStyle={{width: 'auto', float: 'right', margin: 0}} fluid={data[IMAGES[activeFirstImage ? currentImageIndex : prevImageIndex].name].childImageSharp.fluid} />}
          {mobile && <div style={{height: '100%', position: 'relative', width: Math.min((innerHeight - 150) * IMAGES[this.state.currentImageIndex].ratio, innerWidth), margin: '0 auto'}}>
            <Img style={{height: '100%', width: 'auto'}} imgStyle={{width: 'auto', float: 'right', margin: 0}} fluid={data[IMAGES[activeFirstImage ? currentImageIndex : prevImageIndex].name].childImageSharp.fluid} />
          </div>}
        </div>
        <div className="animatedOpacity" style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, opacity: activeFirstImage ? 0 : 1}}>
          {!mobile && <Img style={{height: '100vh', width: 'auto'}} imgStyle={{width: 'auto', float: 'right', margin: 0}} fluid={data[IMAGES[activeFirstImage ? prevImageIndex : currentImageIndex].name].childImageSharp.fluid} />}
          {mobile && <div style={{height: '100%', position: 'relative', width: Math.min((innerHeight - 150) * IMAGES[this.state.currentImageIndex].ratio, innerWidth), margin: '0 auto'}}>
            <Img style={{height: '100%', width: 'auto'}} imgStyle={{width: 'auto', float: 'right', margin: 0}} fluid={data[IMAGES[activeFirstImage ? prevImageIndex : currentImageIndex].name].childImageSharp.fluid} />
          </div>}
        </div>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
          <table style={{height: mobile ? 'calc(100vh - '+ HEADER_SIZE +')' : '100vh', margin: 0}}><tbody><tr>
            <td style={{border: 'none', verticalAlign: 'center', textAlign: 'left'}}>
              <p style={{padding: '10px', margin: 0}} onClick={() => this.newImage(-1)}><FaAngleLeft className="arrow"></FaAngleLeft></p>
            </td>
            <td style={{border: 'none', verticalAlign: 'center', textAlign: 'right'}}>
              <p style={{padding: '10px', margin: 0}} onClick={() => this.newImage(1)}><FaAngleRight className="arrow"></FaAngleRight></p>
            </td>
          </tr></tbody></table>
        </div>
      </div>
    )
  }
}


export default (props) => {
  return (
  <StaticQuery
    query={graphql`
      query {
        image1: file(relativePath: { eq: "gallery/1.jpg" }) {
          ...imageTypeNarrow
        }
        image2: file(relativePath: { eq: "gallery/2.jpg" }) {
          ...imageTypeNarrow
        },
        image3: file(relativePath: { eq: "gallery/3.jpg" }) {
          ...imageTypeNarrow
        },
        image4: file(relativePath: { eq: "gallery/4.jpg" }) {
          ...imageTypeNarrow
        },
        image5: file(relativePath: { eq: "gallery/5.jpg" }) {
          ...imageTypeNarrow
        },
        image6: file(relativePath: { eq: "gallery/6.jpg" }) {
          ...imageTypeNarrow
        },
        image7: file(relativePath: { eq: "gallery/7.jpg" }) {
          ...imageTypeNarrow
        },
        image8: file(relativePath: { eq: "gallery/8.jpg" }) {
          ...imageTypeNarrow
        },
        image9: file(relativePath: { eq: "gallery/9.jpg" }) {
          ...imageTypeNarrow
        },
        image10: file(relativePath: { eq: "gallery/10.jpg" }) {
          ...imageTypeNarrow
        },
        image11: file(relativePath: { eq: "gallery/11.jpg" }) {
          ...imageTypeNarrow
        },
        image12: file(relativePath: { eq: "gallery/12.jpg" }) {
          ...imageTypeNarrow
        },
        image13: file(relativePath: { eq: "gallery/13.jpg" }) {
          ...imageTypeNarrow
        },
        image14: file(relativePath: { eq: "gallery/14.jpg" }) {
          ...imageTypeNarrow
        }
      }
    `}
    render={data =>
      <ImageGallery data={data} onImageChange={props.onImageChange} mobile={props.mobile} info={props.info}/>
    }
  />
)}

