import React from "react"

import Layout from "../components/layout"
import ImageGallery from "../components/imageGallery"
import SEO from "../components/seo"

import "./index.css"

const HEADER_SIZE = "150px";

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: '0px',
      info: false,
      galleryWidthRatio: 0.56,
      mobile: false
    }

    this.toggleInfo = this.toggleInfo.bind(this);

  }

  handleResize() {
    this.setState({
      ...this.state,
      width: (window.innerHeight * this.state.galleryWidthRatio) + 'px',
      mobile: this.isMobile()
    })
  }

  isMobile() {
    return window.innerHeight / window.innerWidth > 0.75
  }

  toggleInfo() {
    this.setState({
      ...this.state,
      width: this.state.info ? (window.innerHeight * this.state.galleryWidthRatio) + 'px' : '0px',
      info: !this.state.info
    })
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  changeGalleryWidthRatio(galleryWidthRatio) {
    this.setState({
      ...this.state,
      width: !this.state.info ? (window.innerHeight * galleryWidthRatio) + 'px' : '0px',
      galleryWidthRatio
    })
  }

  render() {
    const {mobile, width, info} = this.state;

	  return (
    <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    {mobile ? 
      (<div className="mobile-header backgroundBlue animatedMaxHeight" style={{position: 'absolute', zIndex: 1, top: 0, left: 0, right: 0, background: 'blue', padding: '10px', height: '100%', maxHeight: info ? '100%' : HEADER_SIZE}}>
      <table className="animatedHeight" style={{margin: 0, height: info ? '100vh' : HEADER_SIZE}}>
        <tbody>
          <tr>
            <td class="text-container" style={{textAlign: 'center', verticalAlign: 'center', border: 'none'}}>
              <h1 class="logo">od<span class="logo-span">-</span>do</h1>
              <h3 class="logo-sub">arhitektur<span style={{letterSpacing: 0}}>a</span></h3>
              <div style={{marginTop: '-16px', height: '50px', position: 'relative'}}>
                <p style={{position: 'absolute', width: '100%', opacity: info ? 0 : 1}} class="info animateOpacity" onClick={this.toggleInfo}>info</p>
                <p style={{position: 'absolute', width: '100%', opacity: info ? 1 : 0}} class="info animateOpacity" onClick={this.toggleInfo}>gallery</p>
              </div>
              <div style={{opacity: info ? 1 : 0, maxHeight: info ? '200px': '0px', height: 'auto', overflow: 'hidden'}} className="animatedMaxHeight">
                <p>Dalmatinova 2, 1000 Ljubljana</p>
                <a class="link" href="tel:031780913">031 732 894</a>
                <a class="link" href="mailto:info@od-do.si">info@od-do.si</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>)
      :(<div className="backgroundBlue animatedWidth" style={{width: 'calc(100% - ' + width + ')', height:'100vh', background: 'blue', float: 'left'}}>
      <table style={{margin: 0, height: '100%'}}>
        <tbody>
          <tr>
            <td class="text-container" style={{textAlign: 'center', verticalAlign: 'center', border: 'none'}}>
              <h1 class="logo">od<span class="logo-span">-</span>do</h1>
              <h3 class="logo-sub">arhitektur<span style={{letterSpacing: 0}}>a</span></h3>
              <div style={{height: '50px', position: 'relative'}}>
                <p style={{position: 'absolute', width: '100%', opacity: info ? 0 : 1}} class="info animateOpacity" onClick={this.toggleInfo}>info</p>
                <p style={{position: 'absolute', width: '100%', opacity: info ? 1 : 0}} class="info animateOpacity" onClick={this.toggleInfo}>gallery</p>
              </div>
              <div style={{opacity: info ? 1 : 0, maxHeight: info ? '200px': '0px', height: 'auto', overflow: 'hidden'}} className="animatedMaxHeight">
                <p>Dalmatinova 2, 1000 Ljubljana</p>
                <a class="link" href="tel:031780913">031 732 894</a>
                <a class="link" href="mailto:info@od-do.si">info@od-do.si</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>)}
    <ImageGallery onImageChange={this.changeGalleryWidthRatio.bind(this)} mobile={mobile} info={info}></ImageGallery>
		</Layout>
	  );
	}
}
